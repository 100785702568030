.formimage {
  width: 100%;
  max-width: 500px;
}

.form-err-message {
  color: red;
  border: 1px solid red;
  border-radius: 5px;
  padding: 10px;
}

.float-right{
  margin: 10px;
}

.select-booth-checkbock{
  position: absolute;
  background: rgb(39 59 137);
  padding: 10px;
  max-height: 200px;
  overflow-y: scroll;
  color: white;
  top: 100%;
  min-width: 100%;
  border-radius: 5px;
  z-index: 10;
}


.cursor-pt{
  cursor: pointer;
}
